import DcardBoxBox from "../DcardBox";
import OutsideClickHandler from "../OutsideClickHandler";
import "./style.css";
import moment from "moment/moment";
import { removeDuplicatesById } from "../../utils/helper";
// import { playSound } from "../../utils/helper";

const NotificationIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/notification-icon.svg`;
const AwardIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/award-icon.svg`;
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/close-icon.svg`;
// const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;

const Notification = ({
  count,
  onClick,
  loading,
  data,
  onClose,
  isNotificationOpen,
}) => {
  const handleSubmit = () => {
    onClick();
  };

  const handleClose = () => {
    onClose();
  };

  const uniqueNotifications = removeDuplicatesById(data);

  return (
    <div className="notfication-col">
      <OutsideClickHandler onOutsideClick={handleClose}>
        <div className="d-notification-icon" onClick={handleSubmit}>
          <img src={NotificationIcon} alt="Notification-icon" />
          {count > 0 && <span className="not-count">{count}</span>}
        </div>
        <DcardBoxBox
          variant="primary-contained"
          extraClasses={`notification-wrapper ${
            isNotificationOpen ? "noty-active" : ""
          }`}
        >
          <div className="notification-header">
            <h2 className="hrad-text">Notifications</h2>
            <img src={CloseIcon} alt="close-icon" onClick={handleClose} />
          </div>
          <div className="notification-list">
            {loading ? (
              <div className="load-full">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                {uniqueNotifications.length > 0 ? (
                  <ul>
                    {uniqueNotifications.map((item) => (
                      <li
                        key={item._id}
                        className={`not-item ${
                          item.read ? "read-notification" : ""
                        }`}
                      >
                        <div className="d-notification-icon">
                          <img src={AwardIcon} alt="award-icon" />
                        </div>
                        <div>
                          <div style={{ flex: 1 }}>
                            <h3 className="not-title">{item.title}</h3>
                            <p>{item.description}</p>
                          </div>
                          <span>{moment(item.createdAt).fromNow()}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="no-not-data">No Notifications Yet</div>
                )}
              </>
            )}
          </div>
        </DcardBoxBox>
      </OutsideClickHandler>
    </div>
  );
};

export default Notification;
