import React from "react";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FlagList } from "../../../Constants";
const LeftArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/left-arrow.svg`;
const Checkmark = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/checkmark.svg`;

const Dlanguage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const value = search.split("=")[1];
  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left go-back">
            <h2 onClick={() => navigate("/setting")}>
              <img src={LeftArrow} alt="right-arrow" />
              language
            </h2>
          </div>
        </div>
        <div className="langunage-list">
          {FlagList.map((item, index) => {
            return (
              <div
                className={`langunage-item ${
                  value === item.label ? "active" : ""
                }`}
              >
                <div className="user-item" key={index}>
                  <img
                    src={item.image}
                    alt={item.label}
                    className="user-img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://via.placeholder.com/40";
                    }}
                  />
                  {item.label}
                </div>
                {value === item.label && (
                  <img src={Checkmark} alt="check-icon" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Dlanguage;
