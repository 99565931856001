const baseUrl = `${process.env.REACT_APP_S3_BUCKET}/Images`;

const FelsightBg = `${baseUrl}/Felsight-bg.png`;
const FelsightCharactor = `${baseUrl}/Felsight-charactor.png`;
const FelsightIcon1 = `${baseUrl}/Felsight-Icon-1.png`;
const FelsightIcon2 = `${baseUrl}/Felsight-Icon-2.png`;
const FelsightIcon3 = `${baseUrl}/Felsight-Icon-3.png`;
const FelsightIcon4 = `${baseUrl}/Felsight-Icon-4.png`;

const FernixBg = `${baseUrl}/Fernix-bg.png`;
const FernixCharactor = `${baseUrl}/Fernix-charactor.png`;
const FernixIcon1 = `${baseUrl}/Fernix-Icon-1.png`;
const FernixIcon2 = `${baseUrl}/Fernix-Icon-2.png`;
const FernixIcon3 = `${baseUrl}/Fernix-Icon-3.png`;
const FernixIcon4 = `${baseUrl}/Fernix-Icon-4.png`;

const Drysiamain = `${baseUrl}/Drysiamain-min.png`;
const DrysiaBg = `${baseUrl}/Drysia.png`;
const DrysiaCharactor = `${baseUrl}/Drysia-chractor.png`;
const DrysiaIcon1 = `${baseUrl}/Drysia-Icon-1.png`;
const DrysiaIcon2 = `${baseUrl}/Drysia-Icon-2.png`;
const DrysiaIcon3 = `${baseUrl}/Drysia-Icon-3.png`;
const DrysiaIcon4 = `${baseUrl}/Drysia-Icon-4.png`;

const FernoBg = `${baseUrl}/Ferno-bg.png`;
const FernoCharactor = `${baseUrl}/Ferno-charactor.png`;
const FernoIcon1 = `${baseUrl}/Ferno-Icon-1.png`;
const FernoIcon2 = `${baseUrl}/Ferno-Icon-2.png`;
const FernoIcon3 = `${baseUrl}/Ferno-Icon-3.png`;
const FernoIcon4 = `${baseUrl}/Ferno-Icon-4.png`;

const FloraBg = `${baseUrl}/Flora-bg.png`;
const FloraCharactor = `${baseUrl}/Flora-charactor.png`;
const FloraIcon1 = `${baseUrl}/Flora-Icon-1.png`;
const FloraIcon2 = `${baseUrl}/Flora-Icon-2.png`;
const FloraIcon3 = `${baseUrl}/Flora-Icon-3.png`;
const FloraIcon4 = `${baseUrl}/Flora-Icon-4.png`;

const KaliBg = `${baseUrl}/Kali-bg.png`;
const KaliCharactor = `${baseUrl}/Kali-charactor.png`;
const KaliIcon1 = `${baseUrl}/Kali-Icon-1.png`;
const KaliIcon2 = `${baseUrl}/Kali-Icon-2.png`;
const KaliIcon3 = `${baseUrl}/Kali-Icon-3.png`;
const KaliIcon4 = `${baseUrl}/Kali-Icon-4.png`;

const LuminaBg = `${baseUrl}/Lumina-bg.png`;
const LuminaCharactor = `${baseUrl}/Lumina-charactor.png`;
const LuminaIcon1 = `${baseUrl}/Lumina-Icon-1.png`;
const LuminaIcon2 = `${baseUrl}/Lumina-Icon-2.png`;
const LuminaIcon3 = `${baseUrl}/Lumina-Icon-3.png`;
const LuminaIcon4 = `${baseUrl}/Lumina-Icon-4.png`;

const NobleBg = `${baseUrl}/Noble-bg.png`;
const NobleCharactor = `${baseUrl}/Noble-charactor.png`;
const NobleIcon1 = `${baseUrl}/Noble-Icon-1.png`;
const NobleIcon2 = `${baseUrl}/Noble-Icon-2.png`;
const NobleIcon3 = `${baseUrl}/Noble-Icon-3.png`;
const NobleIcon4 = `${baseUrl}/Noble-Icon-4.png`;

const RavosBg = `${baseUrl}/Ravos-bg.png`;
const RavosCharactor = `${baseUrl}/Ravos-charactor.png`;
const RavosIcon1 = `${baseUrl}/Ravos-Icon-1.png`;
const RavosIcon2 = `${baseUrl}/Ravos-Icon-2.png`;
const RavosIcon3 = `${baseUrl}/Ravos-Icon-3.png`;
const RavosIcon4 = `${baseUrl}/Ravos-Icon-4.png`;

const SeraphinaBg = `${baseUrl}/Seraphina-bg.png`;
const SeraphinaCharactor = `${baseUrl}/Seraphina-charactor.png`;
const SeraphinaIcon1 = `${baseUrl}/Seraphina-Icon-1.png`;
const SeraphinaIcon2 = `${baseUrl}/Seraphina-Icon-2.png`;
const SeraphinaIcon3 = `${baseUrl}/Seraphina-Icon-3.png`;
const SeraphinaIcon4 = `${baseUrl}/Seraphina-Icon-4.png`;

const SilverfangBg = `${baseUrl}/Silverfang-bg.png`;
const SilverfangCharactor = `${baseUrl}/Silverfang-charactor.png`;
const SilverfangIcon1 = `${baseUrl}/Silverfang-Icon-1.png`;
const SilverfangIcon2 = `${baseUrl}/Silverfang-Icon-2.png`;
const SilverfangIcon3 = `${baseUrl}/Silverfang-Icon-3.png`;
const SilverfangIcon4 = `${baseUrl}/Silverfang-Icon-4.png`;

const TauroniusBg = `${baseUrl}/Tauronius-bg.png`;
const TauroniusCharactor = `${baseUrl}/Tauronius-charactor.png`;
const TauroniusIcon1 = `${baseUrl}/Tauronius-Icon-1.png`;
const TauroniusIcon2 = `${baseUrl}/Tauronius-Icon-2.png`;
const TauroniusIcon3 = `${baseUrl}/Tauronius-Icon-3.png`;
const TauroniusIcon4 = `${baseUrl}/Tauronius-Icon-4.png`;

const ZephyrionBg = `${baseUrl}/Zephyrion-bg.png`;
const ZephyrionCharactor = `${baseUrl}/Zephyrion-charactor.png`;
const ZephyrionIcon1 = `${baseUrl}/Zephyrion-Icon-1.png`;
const ZephyrionIcon2 = `${baseUrl}/Zephyrion-Icon-2.png`;
const ZephyrionIcon3 = `${baseUrl}/Zephyrion-Icon-3.png`;
const ZephyrionIcon4 = `${baseUrl}/Zephyrion-Icon-4.png`;

const typeIcon = `${baseUrl}/fightingIcon.svg`;
const SingleHerosImg = `${baseUrl}/videoposter.png`;
const Cyclopis = `${baseUrl}/hero-three-main-min.png`;
const fernixmain = `${baseUrl}/fernixmain-min.png`;
const FlamelordHero = `${baseUrl}/hero-four-main-min.png`;
const Paladis = `${baseUrl}/Paladis-min.png`;
const RavenCrow = `${baseUrl}/RavenCrow-min.png`;
const VoltClaw = `${baseUrl}/aatrox-min.png`;
const FloraHero = `${baseUrl}/flora-hero-min.jpg`;
const KalistaHero = `${baseUrl}/Kalista-hero-min.jpg`;
const LuminaHero = `${baseUrl}/Lumina-hero-min.jpg`;
const SeraphinaHero = `${baseUrl}/seraphina-hero-min.jpg`;
const HeroTwo = `${baseUrl}/ahri-min.jpg`; 
const Tauronis = `${baseUrl}/Tauronius-min.png`;
// const Myco = `${baseUrl}/myco-min.png`;

export const HeroesData = [
    {
      image: Drysiamain,
      title: "Drysia",
      subtitle: "The Eclipse Warlord",
      description:
        "In the heart of the shadowed groves of Arcanterra, Drysia stands as the chieftain of whispers and dusk. Clad in the might of the night and wielding twin obsidian axes, she commands the battlefield with the authority of darkness itself. Her presence is a foreboding omen, her power an echo of the void that swallows light.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: DrysiaCharactor,
      bgImage: DrysiaBg,
      SingleHeroIcons: [
        {
          image: DrysiaIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/Q.mp4",
            title: "Spiked Barrage",
            description:
              "Unleashes a flurry of sharp spikes that pierce enemies, causing damage over time.",
          },
        },
        {
          image: DrysiaIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/W.mp4",
            title: "Celestial Drop",
            description:
              "Strikes from above with a powerful impact, dealing with a powerful impact, dealing heavy damage and creating a shockwave that disrupts enemies.",
          },
        },
        {
          image: DrysiaIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/E.mp4",
            title: "Dark Bolt",
            description:
              "Fires a bolt of dark energy that drains life from enemies, weakening them.",
          },
        },
        {
          image: DrysiaIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Drysia/R.mp4",
            title: "Shadow Mend",
            description:
              "uses dark magic to heal herself over time, restoring her strength.",
          },
        },
      ],
    },
    {
      image: Cyclopis,
      charactorImage: FelsightCharactor,
      bgImage: FelsightBg,
      title: "Felsight",
      subtitle: "The Mountain's Gaze",
      description:
        "From the secluded peaks where ancient echoes resonate, Felsight the Unblinking takes his solemn stand in 'Guild of Heroes.' This solitary cyclops, hewn from the very heart of the ancient mountains, is a sentinel of stone and strength. With an eye that pierces through chaos and a focus sharp as the edge of dawn, he surveys the battlefield with an anticipation that belies his imposing form.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleHeroIcons: [
        {
          image: FelsightIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/Q.mp4",
            title: "Cyclops Beam",
            description:
              "Fires a concentrated energy beam from his eye, piercing through multiple enemies.",
          },
        },
        {
          image: FelsightIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/W.mp4",
            title: "Ground Smash",
            description:
              "Strikes the ground to create a shockwave that knocks back and stuns enemies in the area.",
          },
        },
        {
          image: FelsightIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/E.mp4",
            title: "Rock Toss",
            description:
              "Hurls a large rock infused with magical energy, crushing foes upon impact.",
          },
        },
        {
          image: FelsightIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Felsight/R.mp4",
            title: "Stone Skin",
            description:
              "Temporarily hardens his skin, reducing damage taken and gradually healing wounds.",
          },
        },
      ],
    },
    {
      image: fernixmain,
      title: "Fernix",
      subtitle: "The Arcane Protector",
      description:
        "From the ancient roosts where legends take flight, Fernix emerges, his very essence a testament to the might and majesty of dragons of yore. Clad in scales that shimmer with the wisdom of aeons, he stands as a guardian, a mage whose power resonates with the heartbeats of dragons long passed.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: FernixCharactor,
      bgImage: FernixBg,
      SingleHeroIcons: [
        {
          image: FernixIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/Q.mp4",
            title: "Inferno Burst",
            description:
              "Draconis releases a blazing explosion from above, dealing heavy area damage and igniting enemies for additional burning damage.",
          },
        },
        {
          image: FernixIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/W.mp4",
            title: "Flame Wave",
            description:
              "Unleashes a wave of fire that burns and damages enemies in its path.",
          },
        },
        {
          image: FernixIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/E.mp4",
            title: "Arcane Bolt",
            description:
              "Fires a bolt of magical energy that pierces through enemies, dealing substantial damae.",
          },
        },
  
        {
          image: FernixIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Fernix/R.mp4",
            title: "Healing Flame",
            description:
              "Uses the power of fire to mend wounds and restore health over time.",
          },
        },
      ],
    },
    {
      image: FlamelordHero,
      title: "Ferno",
      subtitle: "Elemental Might Unleashed",
      description:
        "From the molten depths of Guild of Heroes comes Ferno, a titanic force of nature whose fiery heart burns with the intensity of a thousand suns. This colossal elemental entity wields the raw power of fire and earth, scorching enemies with his fiery roar and leaving devastation in his wake with every thunderous step.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: FernoCharactor,
      bgImage: FernoBg,
      SingleHeroIcons: [
        {
          image: FernoIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/Q.mp4",
            title: "Flame Roar",
            description:
              "Releases a fiery roar that creates a burning zone around enemies, damaging and repelling foes.",
          },
        },
        {
          image: FernoIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/W.mp4",
            title: "Magma Shot",
            description:
              "Fires a molten rock that burns and slows enemies, dealing damage over time.",
          },
        },
        {
          image: FernoIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/E.mp4",
            title: "Fireball",
            description:
              "Hurls a flaming projectile that explodes on impact, causing area damage and igniting enemies.",
          },
        },
        {
          image: FernoIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ferno/R.mp4",
            title: "Inferno Recovery",
            description:
              "Absorbs heat from the surroundings to restore health, replenishing his vitality in the midst of battle.",
          },
        },
      ],
    },
    {
      image: FloraHero,
      title: "Flora",
      subtitle: "The Sylvan Enchantress",
      description:
        "In every whisper of the wind and rustle of leaves, Flora, the enchanting dryad of 'Guild of Heroes,' moves with the grace of the living forest. She is the guardian of growth, the weaver of woodlands, channeling the ancient power of nature to both nurture and neutralize.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: FloraCharactor,
      bgImage: FloraBg,
      SingleHeroIcons: [
        {
          image: FloraIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/Q.mp4",
            title: "Nature’s Embrace",
            description:
              "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
          },
        },
        {
          image: FloraIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/W.mp4",
            title: "Nature’s Embrace",
            description:
              "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
          },
        },
  
        {
          image: FloraIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/E.mp4",
            title: "Nature’s Embrace",
            description:
              "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
          },
        },
        {
          image: FloraIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Flora/R.mp4",
            title: "Nature’s Embrace",
            description:
              "Summons a protective barrier of vines and leaves around her, shielding against attacks.",
          },
        },
      ],
    },
    {
      image: KalistaHero,
      title: "Kali",
      subtitle: "The Sanguimancer Sovereign",
      description:
        "In the hidden chambers of Arcanterra's forgotten crypts, Kalista, the Enthroned Sanguimancer, reigns with an aura of dread and dominion. She is the arbiter of life and death, weaving the essence of vitality into a tapestry of control and command. Her mastery over the sanguine arts is unrivaled, as she bends the will of friends and foes alike with a mere gesture of her enchanted wand.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: KaliCharactor,
      bgImage: KaliBg,
      SingleHeroIcons: [
        {
          image: KaliIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/Q.mp4",
            title: "Poison Bolt",
            description: "Fires a magical bolt that drains enemy health.",
          },
        },
        {
          image: KaliIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/W.mp4",
            title: "Ethereal Strike",
            description:
              "Delivers a piercing blow that deals damage and weakens enemies.",
          },
        },
        {
          image: KaliIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/E.mp4",
            title: "Dark Orb",
            description:
              "Sends a shadow orb that explodes on contact, causing area damage.",
          },
        },
        {
          image: KaliIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Kali/R.mp4",
            title: "Lifeblood Restoration",
            description:
              "Uses absorbed life force to heal herself, replenishing her vitality.",
          },
        },
      ],
    },
    {
      image: LuminaHero,
      title: "Lumina",
      subtitle: "The Celestial Marksman",
      description:
        "In the quiet before dawn and the hush of dusk, Lumina, the Luminous Archer, takes her stance. She is the embodiment of the sky's dual nature, where the sun's clarity meets the moon's mystery. With a bow that sings the song of the heavens, Lumina strikes with a grace that is as radiant as it is deadly.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: LuminaCharactor,
      bgImage: LuminaBg,
      SingleHeroIcons: [
        {
          image: LuminaIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/Q.mp4",
            title: "Arrow Shot",
            description:
              "Fires a precise arrow at her target, dealing high damage.",
          },
        },
        {
          image: LuminaIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/W.mp4",
            title: "Frost Arrow",
            description:
              "Lumina fires an enchanted ice arrow that deals damage to enemies and inflicts a frostbite effect.",
          },
        },
  
        {
          image: LuminaIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/E.mp4",
            title: "Mystic Arrow",
            description:
              "Launches an arrow that explodes on impact, causing area damage.",
          },
        },
        {
          image: LuminaIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Lumina/R.mp4",
            title: "healing Light",
            description:
              "Channels a beam of light to restore her health and that of nearby allies, enhancing their durability.",
          },
        },
      ],
    },
    {
      image: Paladis,
      title: "Noble",
      subtitle: "The Time-Honored Sentinel",
      description:
        "In the verdant fields and over the storied cobblestones of Arcanterra strides Noble, the weathered knight whose very name evokes tales of valor and perseverance. With the wisdom of countless battles etched into his being, Noble wields his axe not merely as a weapon, but as an extension of a lifetime dedicated to the mastery of martial prowess.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: NobleCharactor,
      bgImage: NobleBg,
      SingleHeroIcons: [
        {
          image: NobleIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/Q.mp4",
            title: "Blazing Arc",
            description:
              "Sends a fiery arc of energy with a swing of his axe, burning and slashing foes.",
          },
        },
        {
          image: NobleIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/W.mp4",
            title: "Arcane Slash",
            description:
              "Strikes with a magical sword, dealing damage and releasing a burst of magical energy that affects enemies in its path.",
          },
        },
  
        {
          image: NobleIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/E.mp4",
            title: "Mystic Axe Throw",
            description:
              "Hurls a magical axe projectile that deals damage and explodes on impact, spreading arcane energy to nearby enemies.",
          },
        },
        {
          image: NobleIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Noble/R.mp4",
            title: "Battle Mend",
            description:
              "Uses battle energy to heal his wounds over time, restoring his strength during combat.",
          },
        },
      ],
    },
    {
      image: RavenCrow,
      title: "Ravos",
      subtitle: "The Ethereal Warrior",
      description:
        "From the shadowed realms of Arcanterra, emerges Ravos, a warrior bound to the spirits of the air. With wings as dark as the raven and eyes glowing with the foresight of the crow, he stands as a sentinel between the worlds. Ravos wields his spectral axe with otherworldly precision, his strikes a blend of the ethereal and the lethal, as he dominates the skies and battlefields.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: RavosCharactor,
      bgImage: RavosBg,
      SingleHeroIcons: [
        {
          image: RavosIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/Q.mp4",
            title: "Raven’s Talon",
            description:
              "Hurls a dark energy projectile that pierces through enemies, dealing damage, and leaving a shadowy mark that weaken.",
          },
        },
  
        {
          image: RavosIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/W.mp4",
            title: "Raven’s Descent",
            description:
              "The character swoops from above with a powerful strike, dealing heavy damage and creating a dark shockwave upon impact.",
          },
        },
        {
          image: RavosIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/E.mp4",
            title: "Bone Spear",
            description:
              "Launches a sharpened bone projectile that pierces and immobilizes foes.",
          },
        },
        {
          image: RavosIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Ravos/R.mp4",
            title: "Necrotic Regrowth",
            description:
              "Uses dark magic to mend bones and flesh, restoring health over time.",
          },
        },
      ],
    },
    {
      image: SeraphinaHero,
      title: "Seraphina",
      subtitle: "The Shadow's Sting",
      description:
        "In the quietest whispers of twilight, Seraphina, the Serpent Assassin of 'Guild of Heroes,' emerges. She is the unseen danger, the silent blade in the dark, whose very presence is as enigmatic as it is lethal. With movements that mirror the serpent's sinuous grace, she strikes with a cold precision known only to the shadows.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: SeraphinaCharactor,
      bgImage: SeraphinaBg,
      SingleHeroIcons: [
        {
          image: SeraphinaIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/Q.mp4",
            title: "Venom Dart",
            description:
              "Fires a poisoned dart that deals damage over time, weakening enemies.",
          },
        },
        {
          image: SeraphinaIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/W.mp4",
            title: "Seraphina’s Eclipse",
            description:
              "Strikes from above to create a circle of dark magic, damaging and weakening enemies within its radius.",
          },
        },
        {
          image: SeraphinaIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/E.mp4",
            title: "Void Exhale",
            description:
              "Seraphina inhales deeply, gathering dark energy, and then exhales is as a projectile that damages enemies and reduces their defenses.",
          },
        },
        {
          image: SeraphinaIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Seraphina/R.mp4",
            title: "Serpent’s Renewal",
            description:
              "Sheds her skin to heal injuries and remove negative effects, restoring health.",
          },
        },
      ],
    },
    {
      image: HeroTwo,
      title: "Silverfang",
      subtitle: "Predator of the Shadows",
      description:
        "Silverfang strikes not just with his limbs but with fear itself. As a beast woven from the nightmares of his enemies, his presence on the battlefield is as chilling as it is deadly. His fearsome howl is the prelude to a symphony of destruction, as his silver-coated claws catch the moonlight before they strike.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: SilverfangCharactor,
      bgImage: SilverfangBg,
      SingleHeroIcons: [
        {
          image: SilverfangIcon1,
          content: {
            video:
              "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/Q.mp4",
            title: "Ferocious Swipe",
            description:
              "Sends a wave of energy with a powerful swipe, knocking back and wounding foes in its path.",
          },
        },
        {
          image: SilverfangIcon2,
          content: {
            video:
              "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/W.mp4",
            title: "Lunar Claw",
            description:
              "The werewolf channels lunar energy into a powerful strike and leaving a glowing lunar mark that causes additional harm over time.",
          },
        },
        {
          image: SilverfangIcon3,
          content: {
            video:
              "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/E.mp4",
            title: "Howling Roar",
            description:
              "Emits a fearsome roar that disorient and damages enemies, lowering their defenses and making them easier to defeat.",
          },
        },
        {
          image: SilverfangIcon4,
          content: {
            video:
              "https://d2j04pqii01bv9.cloudfront.net/videos/Silverfang/E.mp4",
            title: "Lunar Regeneration",
            description:
              "Harness moonlight energy to gradually heal wounds, restoring health over time.",
          },
        },
      ],
    },
    {
      image: Tauronis,
      title: "Tauronius",
      subtitle: "The Horned Vanguard",
      description:
        "In the wild expanses of Arcanterra where nature's call is law, Taurosius, with his twin enchanted axes, reigns supreme. His presence is a force of nature, a testament to the untamed spirit that thrives within the heart of the wilderness. Taurosius wields the power of the earth and wind, a vanguard whose every move resonates with Satyric Harmony.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: TauroniusCharactor,
      bgImage: TauroniusBg,
      SingleHeroIcons: [
        {
          image: TauroniusIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/Q.mp4",
            title: "Celestial Beam",
            description: "Sends forth a magic projectile that deals damage.",
          },
        },
        {
          image: TauroniusIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/W.mp4",
            title: "Earthshaker Stomps",
            description:
              "Stomps the ground to create a shockwave that damages and stuns enemies.",
          },
        },
        {
          image: TauroniusIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/E.mp4",
            title: "Nature’s Wrath",
            description:
              "Launches a burst of magical energy from his axes, striking multiple foes.",
          },
        },
  
        {
          image: TauroniusIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Tauronius/R.mp4",
            title: "Forest’s Embrace",
            description:
              "Channels the power of the forest to heal his wounds over time, restoring vitality.",
          },
        },
      ],
    },
    {
      image: VoltClaw,
      title: "Zephyrion",
      subtitle: "The Storm’s Fury",
      description:
        "Amidst the heroes of Guild of Heroes, Zephyrion stands as a beacon of raw power and electrifying presence. His tragic past fuels his relentless pursuit for justice, channeling the very force of electricity to stun and subdue his adversaries.",
      type: "Fighter",
      typeIcon: typeIcon,
      difficulity: "Moderate",
      abilities: "Abilities",
      SingleImg: SingleHerosImg,
      charactorImage: ZephyrionCharactor,
      bgImage: ZephyrionBg,
      SingleHeroIcons: [
        {
          image: ZephyrionIcon1,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/Q.mp4",
            title: "Electric Spikes",
            description:
              "Fires electrified spikes from his forearm, piercing enemies and delivering a powerful electric shock wave.",
          },
        },
        {
          image: ZephyrionIcon2,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/W.mp4",
            title: "Meteor Strike",
            description:
              "Calls down a massive asteroid that crashes into the ground, creating a powerful shockwave that deals heavy damage and disrupts enemies in the area.",
          },
        },
  
        {
          image: ZephyrionIcon3,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/E.mp4",
            title: "Shock Fist",
            description:
              "Launches an electric punch that travels through the air, stunning and damaging enemies upon impact.",
          },
        },
        {
          image: ZephyrionIcon4,
          content: {
            video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/R.mp4",
            title: "Electro Charge",
            description:
              "Absorbs ambient electricity to restore health over time, enhancing his vitality and resilience.",
          },
        },
      ],
    },
    // {
    //   image: Myco,
    //   title: "Myco",
    //   subtitle: "The Spore Sentinel",
    //   description:
    //     "Maecenas euismod turpis orci, non convallis lectus semper ac. Integer sed erat in enim pulvinar ultricies in et erat.",
    //   type: "Fighter",
    //   typeIcon: typeIcon,
    //   difficulity: "Moderate",
    //   abilities: "Abilities",
    //   SingleImg: SingleHerosImg,
    //   charactorImage: ZephyrionCharactor,
    //   bgImage: ZephyrionBg,
    //   SingleHeroIcons: [
    //     {
    //       image: ZephyrionIcon1,
    //       content: {
    //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/Q.mp4",
    //         title: "Fungal Blast",
    //         description:
    //           "Launches a blast of spores that deals area of effect damage.",
    //       },
    //     },
    //     {
    //       image: ZephyrionIcon2,
    //       content: {
    //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/W.mp4",
    //         title: "Toxic Spores",
    //         description:
    //           "Releases a cloud of toxic spores that deal area of effect damage. The spores also cause a slight poison effect over time.",
    //       },
    //     },
  
    //     {
    //       image: ZephyrionIcon3,
    //       content: {
    //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/E.mp4",
    //         title: "Fungal Bloom",
    //         description:
    //           "Creates a large, explosion like effect that deals area of effect damage and infects enemies inside the radius.",
    //       },
    //     },
    //     {
    //       image: ZephyrionIcon4,
    //       content: {
    //         video: "https://d2j04pqii01bv9.cloudfront.net/videos/Zephyrion/R.mp4",
    //         title: "Vitality Leach",
    //         description:
    //           "Summons a beam of light that connects him to nature's life force and transferring it to heal himself.",
    //       },
    //     },
    //   ],
    // },
  ];