import React from "react";
// import VideoFrame from "../../../Components/VideoFrame";
import { useNavigate } from "react-router-dom";
const bgImage = `${process.env.REACT_APP_S3_BUCKET}/Images/bg1.jpeg`;
const killNpcIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/kill-npc-icon.png`;
const towerKillIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/tower-kill-icon.png`;
const referralEarnIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/referral-earnings-icon.png`;
const referralSingupIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/referral-signup-icon.png`;
const gameLossIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/game-loss-icon.png`;
const gameWinIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/game-win-icon.png`;
const playTimeIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/playtime.png`;
const playerKillIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/player-kill-icon.png`;
// const guildHeroesVideo = `${process.env.REACT_APP_S3_BUCKET}/videos/guild-heroes-uni.mp4`;

const data = [
  {
    icon: playTimeIcon,
    title: "Playtime",
    description: "Earn GOH COINS for every hour you play.",
  },
  {
    icon: gameWinIcon,
    title: "Game win",
    description: "Earn GOH COINS for each game you win.",
  },
  {
    icon: gameLossIcon,
    title: "Game loss",
    description: "Earn a small amount of GOH COINS for each game you lose.",
  },
  {
    icon: playerKillIcon,
    title: "Player kill",
    description: "Earn GOH COINS for each player you kill.",
  },
  {
    icon: towerKillIcon,
    title: "Tower Kill",
    description: "Earn GOH COINS for each tower you destroy.",
  },
  {
    icon: killNpcIcon,
    title: "Kill NPC",
    description: "Earn GOH COINS for each NPC you kill.",
  },
  {
    icon: referralEarnIcon,
    title: "Referral Earnings",
    description: "Earn 10% of all the gold your referred players earn.",
  },
  {
    icon: referralSingupIcon,
    title: "Referral New Signup",
    description: "Earn GOH COINS for each new signup referred.",
  },
];

const Metrices = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-img" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="container">
        <h2 className="h2head text-center">Learn to Earn GOH Coins In-Game</h2>
        <div className="matrices-wrapper">
          <div className="matrices-container">
            {data.map((item, index) => (
              <div className="ways-Item w-50" key={index}>
                <img src={item.icon} width={100} alt="img-icon" />
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="video-container">
            <iframe
              src="https://player.vimeo.com/video/1000473414?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1"
              width="100%"
              height="400"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Complete Guide_ Every Metric You Need to Earn Goh Coins in Guild of Heroes"
            ></iframe>
            {/* <VideoFrame src={guildHeroesVideo} /> */}
            <button onClick={() => navigate("/download")} className="btn mt-4">
              PLAY ON PC
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrices;
