import Button from "../../../Components/Dbutton";
import DModel from "../../../Components/Dmodel";

const DdeleteAccountModel = ({ isActive, onClose }) => {
  return (
    <>
      <DModel
        onClose={() => {
          onClose();
        }}
        isActive={isActive}
      >
        <div className="d-model-text del-modal">
          <h3>delete account</h3>
          <span>Are you sure you want to delete your account?</span>
          <p>
            All your data, including game progress, achievements, and purchases,
            will be permanently deleted. This action cannot be undone.
          </p>
          <Button
            variant="secondary-contained"
            text="Delete account"
            extraClasses="full-contain"
          />

          <Button
            variant="primary-contained"
            text="Cancel"
            extraClasses="w-full"
            onClick={onClose}
          />
        </div>
      </DModel>
    </>
  );
};

export default DdeleteAccountModel;
