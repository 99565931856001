import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/step-one.png`;

const StepsOne = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>Daily and Weekly Tasks</h3>
        <p>
          Start earning rewards by completing your daily and weekly tasks. By
          participating in these tasks, you will earn GOH Coins, which can be
          redeemed in the future for GOH Tokens
        </p>
        <Button
          variant="secondary-contained"
          text="Next"
          extraClasses="full-contain"
          onClick={() =>{
            onNext()
          }}
        />
      </div>
    </>
  );
};

export default StepsOne;
