import React from "react";
import { Link } from "react-router-dom";

function Card({ data, isClickable }) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <>
            {isClickable ? (
              <Link
                className={`product-card ${
                  item?.ComingClass ? item?.ComingClass : ""
                }`}
                key={index}
                to={item.title.split(" ").join("").toLowerCase()}
              >
                <div className="card-img">
                  <img src={item?.image} alt="Heros" />
                  <button className="btn">Click for more info</button>
                </div>

                <h3>{item?.title}</h3>
              </Link>
            ) : (
              <div className="product-card" key={index}>
                <div>
                  <div className="card-img">
                    <img src={item?.image} alt="Heros" />
                  </div>
                  <h3>{item?.title}</h3>
                </div>
              </div>
            )}
          </>
        );
      })}
    </>
  );
}

export default Card;
