import { createSlice } from "@reduxjs/toolkit";
import { taskApi } from "../../services/tasks";
import { mergeData } from "../../utils/helper";
import { WeeklyTaskList } from "../../Constants/list";

const initialState = {
  tasks: [],
  activeTaskCount: 0,
  heroImage: "",
  heroTitle: "",
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setChooseHero: (state, action) => {
      state.heroImage = action.payload.image;
      state.heroTitle = action.payload.title;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        taskApi.endpoints.getUserTasks.matchFulfilled,
        (state, { payload }) => {
          const { data } = payload;
          state.tasks = mergeData(data, WeeklyTaskList);
        }
      )
      .addMatcher(
        taskApi.endpoints.updateUserTask.matchFulfilled,
        (state, { payload }) => {
          const updatedTask = payload.data;
          state.tasks = state.tasks.map((task) =>
            task._id === updatedTask._id ? updatedTask : task
          );
        }
      )
      .addMatcher(
        taskApi.endpoints.claimUserTask.matchFulfilled,
        (state, { payload }) => {
          const updatedTask = payload.data;
          state.tasks = state.tasks.map((task) =>
            task._id === updatedTask._id ? updatedTask : task
          );
        }
      );
  },
});

export default taskSlice;
export const { setChooseHero } = taskSlice.actions;

// Selector to compute activeTaskCount
export const selectActiveTaskCount = (state) =>
  state.task.tasks.filter((task) => task.isActive && !task.isCompleted).length;
