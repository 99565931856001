import io from "socket.io-client";

let socket;
let url = process.env.REACT_APP_BASE_URL;
const baseUrl = url.replace("/api/v1", "");

export const connectSocket = (userId) => {
  socket = io(baseUrl, {
    transports: ["websocket"],
  });

  if (userId) socket.emit("userJoined", userId);

  return socket;
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const subscribeToOnlineUsers = (callback) => {
  socket.on("onlineUsers", callback);
};

export const subscribeToEvens = (callback) => {
  socket.on("events", callback);
};

export const subscribeToNotifications = (userPlayFabId, callback) => {
  socket.on(`notifications_${userPlayFabId}`, callback);
};
