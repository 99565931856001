import React, { useState } from "react";
import "./style.css";
const RightArrowActive = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/arrow-right-active.svg`;
const LeftArrowActive = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/arrow-left-active.svg`;
const RightArrowGrey = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/arrow-right-grey.svg`;
const LeftArrowGrey = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/arrow-left-grey.svg`;

const Dpagination = ({
  currentPage,
  setCurrentPage,
  totalCount,
  nextText,
  backText,
}) => {
  const [isLeftHovered, setIsLeftHovered] = useState(false);
  const [isRightHovered, setIsRightHovered] = useState(false);
  // Calculate total pages
  const totalPages = Math.ceil(totalCount / currentPage.pageSize);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = 3;
    pageNumbers.push(1);

    if (totalPages <= totalPageNumbers + 2) {
      for (let i = 2; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(2, currentPage.pageNo - 1);
      let endPage = Math.min(totalPages - 1, currentPage.pageNo + 1);

      if (currentPage.pageNo <= totalPageNumbers) {
        endPage = totalPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      if (totalPages > 1) {
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <button
        onClick={() =>
          setCurrentPage({
            ...currentPage,
            pageNo: Math.max(currentPage.pageNo - 1, 1),
          })
        }
        className={currentPage.pageNo === 1 ? "disabled" : ""}
        disabled={currentPage.pageNo === 1}
        onMouseEnter={() => setIsLeftHovered(true)}
        onMouseLeave={() => setIsLeftHovered(false)}
      >
        <img
          src={isLeftHovered ? LeftArrowActive : LeftArrowGrey}
          width={16}
          height={16}
          alt="icon"
        />
        {backText && backText}
      </button>

      {getPageNumbers().map((page, index) => (
        <span
          key={index}
          onClick={() => {
            if (page !== "...") {
              setCurrentPage({ ...currentPage, pageNo: page });
            }
          }}
          className={page === currentPage.pageNo ? "active" : ""}
        >
          {page}
        </span>
      ))}

      <button
        onClick={() =>
          setCurrentPage({
            ...currentPage,
            pageNo: Math.min(currentPage.pageNo + 1, totalPages),
          })
        }
        className={currentPage.pageNo === totalPages ? "disabled" : ""}
        disabled={currentPage.pageNo === totalPages}
        onMouseEnter={() => setIsRightHovered(true)}
        onMouseLeave={() => setIsRightHovered(false)}
      >
        {nextText && nextText}
        <img
          src={isRightHovered ? RightArrowActive : RightArrowGrey}
          width={16}
          height={16}
          alt="icon"
        />
      </button>
    </div>
  );
};

export default Dpagination;
