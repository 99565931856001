import React, { 
  // useState
 } from "react";
// import Model from "../../../Components/Model";
import Card from "../../../Components/productCard";
// import { ProductTabsList } from "../../../Constants/index";
import { HeroesData, 
  // Assassuns
 } from "../../../Constants/index";

function HeroesList() {
  // const [active, setActive] = useState(0);
  // const [isActive, setIsActive] = useState(false);
  // const handleClick = () => {
  //   setIsActive((current) => !current);
  // };
  return (
    <section className="">
      {/* <div className="filter-btn">
        <span className={isActive ? "active" : ""} onClick={handleClick}>
          FILTER HEROES
        </span>
      </div>
      <Model
        isActive={isActive}
        active={active}
        setActive={setActive}
        handleClick={handleClick}
        tabs={ProductTabsList}
        data={[ 
          HeroesData,
          Assassuns,
          HeroesData,
          Assassuns,
          HeroesData,
          Assassuns,
          HeroesData,
        ]}
      /> */}

      <div className="heroes-tabs-content">
        <div className="container">
          <div className="row">
            {HeroesData.length > 0 ? (
              <Card data={HeroesData} isClickable/>
            ) : (
              <h1
                className="h3head"
                style={{ textAlign: "center", fontFamily: "Rakkas" }}
              >
                No results found.
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroesList;
