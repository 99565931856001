/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { navMenu } from "../../Constants";
import { Divider, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Sidebar from "../../Components/Sidebar";
import ProfileBtn from "../../Components/ProfileBtn";
import CountUpAnimation from "../../Components/CountUpAnimation";
import { useSelector } from "react-redux";
import DcurrencySelect from "../../Components/DcurrencySelect";

const logo = `${process.env.REACT_APP_S3_BUCKET}/Images/logo_horizontal_dark.svg`;
const logoIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Dark.svg`;
const ToggleIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/toggle.svg`;
const ProfileIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/profile-icon.svg`;

const Header = ({ active, handleClick }) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  const contentStyle = {
    backgroundColor: "#000",
    borderRadius: 20,
    zIndex: 10000,
    marginTop: 10,
  };
  const menuStyle = {
    boxShadow: "none",
    background: "#191C1F",
  };

  return (
    <>
      <header className="head">
        <div className="leftdiv">
          <Link to="/">
            <img src={isSmallScreen ? logoIcon : logo} alt="logo" />
          </Link>
          <ul className="nav">
            {navMenu &&
              navMenu?.length &&
              navMenu?.map((item, index) => {
                if (item.isPrivate && !user) {
                  return null; // Don't render the "Airdrop" menu if user is not logged in
                }

                return (
                  <li key={index}>
                    {!item.subMenu?.length ? (
                      <Link to={item.link}>{item.title}</Link>
                    ) : (
                      <Dropdown
                        style={{ margin: "100px" }}
                        menu={{ items: item?.subMenu }}
                        trigger={["click"]}
                        dropdownRender={(menu) => (
                          <div style={contentStyle}>
                            {React.cloneElement(menu, {
                              style: menuStyle,
                              className: "dropdown-menu",
                            })}
                            <Divider
                              style={{
                                margin: 0,
                              }}
                            />
                          </div>
                        )}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <Space style={{ cursor: "pointer" }}>
                            {item.title}
                            <DownOutlined />
                          </Space>
                        </a>
                      </Dropdown>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="rightdiv">
          <DcurrencySelect />
          {!user ? (
            <>
              <a href={process.env.REACT_APP_LOGIN_URL} className="log-btn">
                LOG IN
              </a>
              <a href={process.env.REACT_APP_SIGNUP_URL} className="log-btn">
                REGISTER
              </a>
            </>
          ) : (
            <>
              <div className="rakec">
                <img src={ProfileIcon} alt="profile-icon" />
                <div className="lerak">
                  <p>GOH COINS EARNED</p>
                  <span>
                    <CountUpAnimation amount={user?.gohCoins || "--"} />{" "}
                  </span>
                </div>
              </div>
              <ProfileBtn handleClick={() => {}} username={user?.displayName || user?.userName} />
            </>
          )}
        </div>
        <div className="toggle-menu">
          <img
            src={ToggleIcon}
            className={active ? "active" : ""}
            onClick={handleClick}
            alt="Toggle Icon"
          />
        </div>
        <Sidebar
          active={active}
          handleClick={handleClick}
          isOpenSubMenu={isOpenSubMenu}
          setIsOpenSubMenu={setIsOpenSubMenu}
        />
      </header>
    </>
  );
};

export default Header;
