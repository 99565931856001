import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/device-one.png`;

const StepsOne = ({ onNext, onback }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>Dashboard Earnings</h3>
        <p className="d-model-desc">
          Participate in daily tasks on the dashboard to consistently earn GOH
          Coins. Active engagement is rewarded with fresh opportunities each day
        </p>
        <div className="flex">
          <Button
            variant="secondary-contained"
            text="Go to dashboard"
            extraClasses="w-full"
            onClick={onback}
          />
          <Button
            variant="primary-contained"
            text="Next"
            extraClasses="w-full"
            onClick={onNext}
          />
        </div>
      </div>
    </>
  );
};

export default StepsOne;
