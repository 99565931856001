import { useState } from "react";
import DcardBoxBox from "../DcardBox";
import OutsideClickHandler from "../OutsideClickHandler";
import { FlagList } from "../../Constants/list";
import "./style.css";

const worldIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/world-icon.svg`;

const DcurrencySelect = () => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectect, setIsSelectect] = useState("");
  return (
    <div className="currency-col">
      <OutsideClickHandler onOutsideClick={() => setIsSelectOpen(false)}>
        <div
          className="d-currency-icon"
          // onClick={() => setIsSelectOpen(!isSelectOpen)}
          title="Coming soon"
          style={{ cursor: "not-allowed", opacity: 0.6 }}
        >
          <img src={worldIcon} alt="currency-icon" />
          {isSelectect && <span>{isSelectect}</span>}
        </div>
        <DcardBoxBox
          variant="primary-contained"
          extraClasses={`currency-wrapper ${
            isSelectOpen ? "currency-active" : ""
          }`}
        >
          <div className="currency-list">
            <ul>
              {FlagList.map((item, index) => (
                <li
                  className="cur-item"
                  key={index}
                  onClick={() => {
                    setIsSelectect(item.value);
                    setIsSelectOpen(false);
                  }}
                >
                  <div className="d-currency-icon">
                    <img src={item.image} alt="currency-icon" />
                  </div>
                  <div>
                    <h3 className="currency-title">
                      {item.label}
                      {`(${item.value})`}
                      {/* Call the function correctly */}
                    </h3>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </DcardBoxBox>
      </OutsideClickHandler>
    </div>
  );
};

export default DcurrencySelect;
