import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/step-five.png`;

const StepsFive = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>refer friends</h3>
        <p>
        Invite friends to join and play Guild of Heroes on the dashboard or through the Telegram mini-app. Earn 10% of all the GOH Coins they accumulate from tasks, game victories, and player kills. Expand your network and enhance your earnings seamlessly across both platforms!
        </p>
        <Button
          variant="secondary-contained"
          text="Next"
          extraClasses="full-contain"
          onClick={() =>{
            onNext()
          }}
        />
      </div>
    </>
  );
};

export default StepsFive;
