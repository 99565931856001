import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getNotifications: builder.mutation({
      query: (id) => {
        return {
          url: `/notifications/get-notifications/${id}`,
          method: "GET",
        };
      },
    }),
    updateNotifications: builder.mutation({
      query: (body) => {
        return {
          url: `/notifications/read-notifications`,
          method: "PATCH",
          body: body,
        };
      },
    }),
  }),
});

export const { useGetNotificationsMutation, useUpdateNotificationsMutation } =
  notificationApi;
