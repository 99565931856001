export function truncateURL(url, maxLength) {
  if (url.length <= maxLength) {
    return url;
  }

  const partLength = Math.floor((maxLength - 3) / 2);
  const firstPart = url.substring(0, partLength);
  const lastPart = url.substring(url.length - partLength, url.length);

  return `${firstPart}...${lastPart}`;
}

export function truncateID(str) {
  if (str.length > 6) {
    const firstPart = str.slice(0, 6);  
    const lastPart = str.slice(-6);     
    return `${firstPart}...${lastPart}`;  
  }
  return str;  // Return as is if the string is 6 characters or less
}

export function isNew(createdAt) {
  const oneDayInMilliseconds = 1 * 24 * 60 * 60 * 1000; // 1 day in milliseconds
  const createdAtDate = new Date(createdAt); // Convert createdAt string to a Date object
  const currentDate = new Date(); // Get the current date

  // Check if the difference between current date and createdAt date is less than 1 day
  return (currentDate - createdAtDate) < oneDayInMilliseconds;
}

export const formatLargeNumber  = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return 'N/A'; // Return a placeholder if the input is not a valid number
  }

  const formatter = new Intl.NumberFormat ("en-US");

  if (number >= 1000000) {
    const millions = number / 1000000;
    const formattedNumber = formatter.format(millions);
    return `${formattedNumber}M`;
  } else if (number >= 1000) {
    const thousands = number / 1000;
    const formattedNumber = formatter.format(thousands);
    return `${formattedNumber}K`;
  } else {
    return number.toString();
  }
};