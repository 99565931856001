import "./style.css";
import StepsOne from "./stepOne";
import StepsTwo from "./stepTwo";
import StepsThree from "./stepThree";
import StepsFour from "./stepFour";
import StepsFive from "./stepFive";
import StepsSix from "./stepSix";
import Dmodel from "../../../Components/Dmodel";
import { useEffect, useState } from "react";

const DailyTaskModels = ({ activeModel, isActive, onClose }) => {
  const [currentPopup, setCurrentPopup] = useState(activeModel);

  useEffect(() => {
    if (activeModel) {
      setCurrentPopup(activeModel);
    }
  }, [activeModel]);

  const modals = {
    stepone: <StepsOne onNext={() => setCurrentPopup("steptwo")} />,
    steptwo: <StepsTwo onNext={() => setCurrentPopup("Stepsthree")} />,
    Stepsthree: <StepsThree onNext={() => setCurrentPopup("stepfour")} />,
    stepfour: <StepsFour onNext={() => setCurrentPopup("stepfive")} />,
    stepfive: <StepsFive onNext={() => setCurrentPopup("stepsix")} />,
    stepsix: (
      <StepsSix
        onNext={() => {
          setCurrentPopup("stepone");
          onClose();
        }}
      />
    ),
  };

  const steps = Object.keys(modals);
  const currentIndex = steps.indexOf(currentPopup);

  return (
    <Dmodel
      steps={steps.length}
      isCurrentStep={currentIndex}
      onClose={onClose}
      isActive={isActive}
      extraClasses="step-modal"
    >
      {modals[currentPopup]}
    </Dmodel>
  );
};

export default DailyTaskModels;
