import React, { useState } from "react";
import "./style.css";
import DdeleteAccountModel from "./DdeleteAccountModel";
// import { useNavigate } from "react-router-dom";
const RightArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/right-arrow.svg`;

const Dsetting = () => {
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  // const navigate = useNavigate();
  return (
    <>
      <div className="d-content">
        <div className="d-top-bar">
          <div className="d-top-left">
            <h2>settings</h2>
          </div>
        </div>
        <div className="d-setting-lsit">
          <div
            className="setting-item"
            title="Coming soon"
            // onClick={() => navigate("/setting/language?language=English")}
          >
            <div className="fouteen-size">
              select language
              <span>English</span>
            </div>
            <img src={RightArrow} alt="right-arrow" />
          </div>
          <div
            className="setting-item"
            title="Coming soon"
            // onClick={() => setIsDeleteAccount(true)}
          >
            <div className="fouteen-size">Delete account</div>
            <img src={RightArrow} alt="right-arrow" />
          </div>
        </div>
      </div>

      <DdeleteAccountModel
        isActive={isDeleteAccount}
        onClose={() => {
          setIsDeleteAccount(false);
        }}
      />
    </>
  );
};

export default Dsetting;
