import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const combatEarningApi = createApi({
  reducerPath: "combatEarningApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCombatCards: builder.mutation({
      query: () => ({
        url: `/combat-earn/cards`,
        method: "GET",
      }),
    }),
    getCombatEarning: builder.mutation({
      query: (playFabId) => ({
        url: `/combat-earn/get-user-earnings/${playFabId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetCombatCardsMutation,useGetCombatEarningMutation } = combatEarningApi;
