export const event = {
  MATCHFINISHED: "Match_Finished",
  TASKCOMPLETE: "task_completed",
};

export const eventsTypes = {
  Played_1_Hour: "Played_1_Hour",
  Won_a_Game: "Won_a_Game",
  Lost_a_Game: "Lost_a_Game",
  Killed_a_Player: "Killed_a_Player",
  Killed_a_Tower: "Killed_a_Tower",
  Killed_NPC: "Killed_NPC",
  Tap_on_Character: "Tap_on_Character",
  Picked_Hero: "Picked_Hero",
  Watch_YouTube: "Watch_YouTube",
  Followed_on_X: "Followed_on_X",
  Followed_on_Facebook: "Followed_on_Facebook",
  Followed_our_Kick_Channel: "Followed_our_Kick_Channel",
  Followed_on_Instagram: "Followed_on_Instagram",
  Joined_Telegram: "Joined_Telegram",
  Joined_ANN_Channel: "Joined_ANN_Channel",
  Joined_Discord: "Joined_Discord",
  Liked_And_Shared_Post: "Liked_And_Shared_Post",
  Created_Account: "Created_Account",
  Claimed_Daily_Reward: "Claimed_Daily_Reward",
};

const eventsPoints = {
  Played_1_Hour: 125000,
  Won_a_Game: 60000,
  Lost_a_Game: 15000,
  Killed_a_Player: 2500,
  Killed_a_Tower: 10000,
  Killed_NPC: 25,
  Tap_on_Character: 3,
  Picked_Hero: 5000,
  Watch_YouTube: 200000,
  Followed_on_X: 100000,
  Joined_Telegram: 50000,
  Joined_ANN_Channel: 50000,
  Liked_And_Shared_Post: 50000,
  Created_Account: 10000,
};

export const createEvent = async (eventName, reward) => {
  const taskRequest = {
    EventName: event.TASKCOMPLETE,
    Body: {
      type: eventName,
      reward: reward,
    },
  };

  await window.PlayFab.ClientApi.WritePlayerEvent(taskRequest);
};

export const generateRandomData = (users) => {
  const getRandomEvent = () => {
    const eventTypes = Object.keys(eventsPoints);
    const randomIndex = Math.floor(Math.random() * eventTypes.length);
    return eventTypes[randomIndex];
  };

  const getRandomReward = (eventType) => {
    return eventsPoints[eventType];
  };
  const getRandomUserName = (ind) => {
    return users[ind]?.displayName;
  };
  const getRandomAvatar = (ind) => {
    return users[ind]?.avatarUrl;
  };

  const randomDataArray = [];

  for (let i = 0; i < 10; i++) {
    const userName = getRandomUserName(i);
    const playerAvatar = getRandomAvatar(i);
    const eventType = getRandomEvent();
    const rewardEarned = getRandomReward(eventType);

    const randomData = {
      userName,
      playerAvatar,
      eventType,
      rewardEarned,
    };

    randomDataArray.push(randomData);
  }

  return randomDataArray;
};

export const mergeAndSumRewards = (data) => {
  const mergedData = {};

  data.forEach((item) => {
    const key = `${item.playFabId}-${item.eventType}`;

    if (!mergedData[key]) {
      mergedData[key] = {
        playFabId: item.playFabId,
        userName: item.userName,
        eventType: item.eventType,
        rewardEarned: item.rewardEarned,
        count: item.count,
      };
    } else {
      mergedData[key].rewardEarned += item.rewardEarned;
      mergedData[key].count += item.count;
    }
  });

  // Convert the merged data back into an array
  return Object.values(mergedData);
};

export const getEventType = (type) => {
  let newType;
  switch (type) {
    case "Choose-your-hero":
      newType = eventsTypes.Picked_Hero;
      break;
    case "Watch-the-youtube-video":
    case "Watch-youtube-video":
      newType = eventsTypes.Watch_YouTube;
      break;
    case "Follow-us-on-X":
      newType = eventsTypes.Followed_on_X;
      break;
    case "Follow-us-on-instagram":
      newType = eventsTypes.Followed_on_Instagram;
      break;
    case "Follow-us-on-Facebook":
      newType = eventsTypes.Followed_on_Facebook;
      break;
    case "Follow-our-kick-channel":
      newType = eventsTypes.Followed_our_Kick_Channel;
      break;
    case "Join-to-our-Telegram-channel":
      newType = eventsTypes.Joined_Telegram;
      break;
    case "Join-Announcement-channel":
      newType = eventsTypes.Joined_ANN_Channel;
      break;
    case "Like-and-share-our-post":
      newType = eventsTypes.Liked_And_Shared_Post;
      break;
    case "Join-us-on-discord":
      newType = eventsTypes.Joined_Discord;
      break;
    default:
      newType = type;
  }

  return newType;
};
