import Button from "../../../../Components/Dbutton";
import { currencyFormat } from "../../../../utils/helper";

const LogoIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/dailyTask-icon.svg`;

const SocialTask = ({ data, onJoin, onCheck }) => {
  return (
    <>
      <div className="d-model-text task-model">
        <h3>{data?.title}</h3>
        <div
          className="d-icon"
          style={{
            background: data?.bgColor,
            boxShadow: `0 0 50px ${data?.bgColor}`,
          }}
        >
          <img src={data?.logo} alt="logo" />
        </div>
        <div className="icon-point">
          <h4>
            {"+"}
            {currencyFormat(Number(data?.coins))}
          </h4>
          <img src={LogoIcon} alt="Logo-icon" />
        </div>
        <Button
          variant="secondary-contained"
          text={`${data?.buttonText}`}
          extraClasses="full-contain mb2"
          onClick={() => onJoin(data)}
        />
        <Button
          variant="primary-contained"
          text="check"
          extraClasses="w-full"
          disabled={true}
          onClick={() => onCheck(data)}
        />
      </div>
    </>
  );
};

export default SocialTask;
