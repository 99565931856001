/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import Modal from "../Modal";
import { profileDetailformSchema } from "../../utils/validationSchema";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useUpdateUserProfileMutation } from "../../services/user";
import {
  ErrorOption,
  SuccessOption,
  WarnOption,
} from "../../utils/toastOptions";

const ProfileDetail = ({ userData, setProfileProgress, fetchUserData }) => {
  const { playFabInfo, user } = useSelector((state) => state.auth);
  const [email, setEmail] = useState(
    user.email
      ? user.email
      : playFabInfo?.AccountInfo?.GoogleInfo
      ? playFabInfo?.AccountInfo?.GoogleInfo?.GoogleEmail
      : playFabInfo?.AccountInfo?.FacebookInfo
      ? playFabInfo?.AccountInfo?.FacebookInfo?.FacebookId
      : playFabInfo?.AccountInfo?.PrivateInfo?.Email
  );

  const [form, setForm] = useState({
    country: userData?.country,
    dob: userData?.dob,
  });
  const [error, setError] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [updateProfile] = useUpdateUserProfileMutation();

  const handleFocus = () => {
    setIsFocused(true);
  };

  // THis function is used to mask the email to it appears as ***df***@u***
  const maskedEmail = (email) => {
    if (!email?.includes("@")) {
      return email;
    }
    const [localPart, domainPart] = email?.split("@");

    const maskedLocalPart =
      localPart?.slice(0, 3) +
      "*".repeat(Math.max(0, localPart.length - 3)) +
      "@"; // Include "@" symbol in the masked part

    return maskedLocalPart + domainPart;
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const err = fieldValidations();
    if (!err) {
      toast(
        <Modal
          message="You are about to update your profile details."
          action="Are you sure you want to proceed?"
          handler={handleUpdateDetails}
          btnText="Proceed"
        />,
        WarnOption
      );
    } else {
      const errorMessage = Object.values(err)[0];
      toast(
        <Modal
          message={errorMessage}
          action=""
          handler={toast.dismiss}
          btnText=""
        />,
        ErrorOption
      );
    }
  };

  const handleUpdateDetails = () => {
    const changedFields = {};

    // Check each field to see if it has been modified
    if (form.dob !== userData?.dob) {
      changedFields.dob = form.dob;
    }
    if (form.country !== userData?.country) {
      changedFields.country = form.country;
    }
    if (email !== userData?.email) {
      changedFields.email = email;
      // set email on playFab 
      setEmailForPlayFab();
    }

    // If there are no changed fields, exit early
    if (Object.keys(changedFields).length === 0) {
      toast.dismiss();
      toast(
        <Modal
          message={"No changes detected."}
          action=""
          handler={toast.dismiss()}
          btnText=""
        />,
        WarnOption
      );
      return;
    }

    // Add userId to the request body
    changedFields.userId = userData?._id;

    


    updateProfile({ ...changedFields, userName: userData?.userName })
      .unwrap()
      .then(() => {
        setProfileProgress(true);
        fetchUserData();
        toast.dismiss();
        toast(
          <Modal
            message={"Profile details updated successfully."}
            action=""
            handler={toast.dismiss()}
            btnText=""
          />,
          SuccessOption
        );
      })
      .catch((err) => {
        toast.dismiss();
        toast(
          <Modal
            message={err?.data?.error || "Failed Updating Profile Details."}
            action=""
            handler={toast.dismiss()}
            btnText=""
          />,
          ErrorOption
        );
      });
  };

  const setEmailForPlayFab = () => {
    window.PlayFab.ClientApi.AddUsernamePassword(
      { Email: email, Password: 123456789, Username: userData?.userName },
      (res, error) => {
        if (res) {
          console.log("email updated successfully");
        } else {
          console.log(error);
        }
      }
    );
  };

  const fieldValidations = () => {
    const { error } = profileDetailformSchema.validate(form, {
      abortEarly: false,
    });

    if (error) {
      const newErrors = error.details.reduce((acc, currentError) => {
        acc[currentError.path[0]] = currentError.message;
        return acc;
      }, {});

      setError(newErrors);
      return newErrors;
    } else {
      setError({});
    }
  };

  // handle change in date
  const handleDateChange = (date) => {
    setForm({
      ...form,
      dob: date ? date.format("YYYY-MM-DD") : "",
    });
  };

  // Validate date picker
  const calculateMaxDate = () => {
    return dayjs().subtract(13, "year");
  };

  useEffect(() => {
    if (form.country && form.dob) {
      setProfileProgress(true);
    }
  }, []);

  return (
    <div className="tbbox profiledetail" id="profiledetail">
      <h5>Profile Details</h5>
      <p>
        Your personal information is kept private and never shared outside the
        gaming environment.
      </p>
      <form className="input-container" autoComplete="off">
        <label className={email ? `has-value disabled` : ``}>
          <span>Email Address</span>
          <input
            disabled={user?.email}
            type="text"
            value={isFocused ? email : maskedEmail(email)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="off"
            className={error.email ? "input-error" : ""}
          />
        </label>
        <div className="two-inputs">
          <label
            className={form.country ? `has-value select-label` : `select-label`}
          >
            <span>Country / Region</span>
            <select
              name="country"
              value={form.country}
              className="country-select"
              onChange={(e) =>
                setForm({
                  ...form,
                  country: e.target.value,
                })
              }
            >
              <option value="Country/Region"></option>
              {options.map((country, index) => {
                return (
                  <option key={index} value={country.label}>
                    {country.label}
                  </option>
                );
              })}
            </select>
          </label>
          <div className="date-input-container" onFocus={handleFocus}>
            {!isFocused && !form.dob ? (
              <label className={form.dob ? "has-value" : ""}>
                <span>Birthday</span>
                <input
                  type="text"
                  autoComplete="off"
                  className={error.dob ? "input-error" : ""}
                />
              </label>
            ) : (
              <div className="dateicker">
                <label className={"date-input has-value"}>
                  {isFocused ? (
                    <span>Date of birth</span>
                  ) : (
                    <span>Date of birth</span>
                  )}
                </label>
                <Space direction="vertical" className="w-full">
                  <DatePicker
                    size="large"
                    className="w-full"
                    value={form.dob ? dayjs(form.dob, "YYYY-MM-DD") : ""}
                    onChange={(e) => handleDateChange(e)}
                    maxDate={calculateMaxDate()}
                    variant="borderless"
                    suffixIcon={null}
                    style={{ paddingTop: 8, color: "#ede5ce", width: "100%" }}
                    allowClear={false}
                  />
                </Space>
                {error?.dob && <div className="error-message">{error.dob}</div>}
              </div>
            )}
          </div>
          {/* <div className="date-input-container" onFocus={handleFocus}>
            {!isFocused && !form.dob ? (
              <label className={form.dob ? "has-value" : ""}>
                <span>Birthday</span>
                <input
                  type="text"
                  autoComplete="off"
                  className={error.dob ? "input-error" : ""}
                />
              </label>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <label className={"date-input has-value"}>
                  {isFocused ? (
                    <span>Date of birth</span>
                  ) : (
                    <span>Date of birth</span>
                  )}
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    value={form.dob}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        dob: e.target.value,
                      })
                    }
                    autoComplete="off"
                    className={error.dob ? "input-error" : ""}
                  />
                </label>
                {error?.dob && <div className="error-message">{error.dob}</div>}
              </div>
            )}
          </div> */}
        </div>
      </form>
      <button
        style={{ marginBottom: "20px" }}
        className="btn update-btn"
        onClick={handleUpdate}
      >
        Update
      </button>
    </div>
  );
};

export default ProfileDetail;
