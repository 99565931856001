import { toast } from "react-toastify";
const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

export const WarnOption = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.WARNING,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

export const SuccessOption = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

export const ErrorOption = {
  autoClose: 2000,
  closeButton: closeButton,
  closeOnClick: true,
  type: toast.TYPE.ERROR,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};
