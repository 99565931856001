import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const friendsApi = createApi({
  reducerPath: "friendsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFriends: builder.mutation({
      query: ({ id, params }) => ({
        url: `/refers/${id}`,
        method: "GET",
        params: params,
      }),
    }),
    // getFriendTask: builder.mutation({
    //   query: () => ({
    //     url: `/social-tasks?type=Friend`,
    //     method: "GET",
    //   }),
    // }),
    // getLoginUserFriendTask: builder.mutation({
    //   query: (id) => ({
    //     url: `/social-tasks/available/${id}?type=Friend`,
    //     method: "GET",
    //   }),
    // }),
  }),
});

export const {
  useGetFriendsMutation,
  // useGetFriendTaskMutation,
  // useGetLoginUserFriendTaskMutation,
} = friendsApi;
