import React from "react";
import PlayerID from "./PlayerID";
import ProfileDetail from "./ProfileDetail";
import AccountSettings from "./AccountSettings";
import TwoFA from "./TwoFA";
import UpdatePreferences from "./UpdatePreferences";

const Setting = ({
  userData,
  fetchUserData,
  setPlayerIDProgress,
  setProfileProgress,
  setWalletProgress,
  setTwofaProgress,
  setPreferencesProgress,
  setAccountProgress,
}) => {
  return (
    <>
      <PlayerID
        userData={userData}
        fetchUserData={fetchUserData}
        setPlayerIDProgress={setPlayerIDProgress}
        setWalletProgress={setWalletProgress}
      />
      <ProfileDetail
        userData={userData}
        fetchUserData={fetchUserData}
        setProfileProgress={setProfileProgress}
      />
      <AccountSettings setAccountProgress={setAccountProgress} />
      <TwoFA userData={userData} setTwofaProgress={setTwofaProgress} />
      {/* <SessionSetting /> */}
      <UpdatePreferences
        userData={userData}
        setPreferencesProgress={setPreferencesProgress}
      />
    </>
  );
};

export default Setting;
