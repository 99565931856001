import Button from "../../../Components/Dbutton";

const StepIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/step-three.png`;

const StepsThree = ({ onNext }) => {
  return (
    <>
      <div className="d-model-icon">
        <img src={StepIcon} alt="" />
      </div>
      <div className="d-model-text">
        <h3>Daily Tasks</h3>
        <p>
          Complete simple activities each day to earn GOH Coins. These tasks
          reset daily, so be sure to check back regularly
        </p>
        <Button
          variant="secondary-contained"
          text="Next"
          extraClasses="full-contain"
          onClick={() =>{
            onNext()
          }}
        />
      </div>
    </>
  );
};

export default StepsThree;
