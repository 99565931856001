/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Modal from "../Modal";
import { useUpdateUserProfileMutation } from "../../services/user";
import { ErrorOption, SuccessOption } from "../../utils/toastOptions";
const copyIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/copy-icon.svg`;
const closeButton = `${process.env.REACT_APP_S3_BUCKET}/Images/closeIcon.svg`;

const options = {
  autoClose: false,
  closeButton: closeButton,
  closeOnClick: false,
  type: toast.TYPE.WARNING,
  hideProgressBar: true,
  position: "top-center",
  style: { border: "1px solid #d0a755" },
};

const PlayerID = ({
  userData,
  fetchUserData,
  setPlayerIDProgress,
  setWalletProgress,
}) => {
  const [tagline, setTagline] = useState("");
  const [walletIcon, setWalletIcon] = useState(null);
  const [isDisabled] = useState(false);
  const { address, connector: activeConnector } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const [form, setForm] = useState({
    gameName: userData.displayName || "",
    tagline: userData?.tagline || userData?.displayName + tagline,
  });
  const [error, setError] = useState({});
  const textRef = useRef();
  const btnRef = useRef(null);
  const [updateProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (address) {
      setWalletProgress(true);
      // handleConnectDisconnect(address);
    } else {
      setWalletProgress(false);
    }
  }, [address]);
 
  useEffect(() => {
    if (userData?.displayName) {
      setPlayerIDProgress(true);
      // handleConnectDisconnect(address);
    } else {
      setPlayerIDProgress(false);
    }
  }, [userData?.displayName]);

  useEffect(() => {
    setWalletIcon(localStorage.getItem("@w3m/connected_wallet_image_url"));
  }, [activeConnector]);

  useEffect(() => {
    const tag = randomHashGenerator();
    setTagline(tag);
  }, [form.tagline]);

  const showToast = (message, options) => {
    toast(
      <Modal
        message={message}
        action=""
        handler={toast.dismiss()}
        btnText=""
      />,
      options
    );
  };

  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.success("Copied to Clipboard");
    }
  };

  const addOneYear = (givenDate) => {
    const date = new Date(givenDate); 
    date.setFullYear(date.getFullYear() + 1); 
    return date;
  };

  const handleUsernameChange = () => {
    const newUpdateTime = addOneYear(userData?.displayNameLastUpdate);
    const currentDate = new Date();
    if (newUpdateTime.getTime() > currentDate.getTime()) {
      showToast("Display name cannot be updated within a year", ErrorOption);
      return;
    }
    const err = fieldValidations();
    if (!err) {
      const toastId = toast(
        <Modal
          message="Please note: You can change your username once per year. Choose your new username carefully to ensure it reflects your desired online identity for the next 12 months. Once you submit a new username, you won't be able to change it again until the same date next year."
          action="Are you sure you want to proceed?"
          handler={() => handler(toastId)} // Pass toastId to the handler
          btnText="Proceed"
        />,
        options
      );
    }
  };

  const setDisplayName = () => {
    window.PlayFab.ClientApi.UpdateUserTitleDisplayName(
      { DisplayName: form.gameName },
      (res, error) => {
        if (res) {
          console.log("display name added");
        } else {
          console.log(error);
        }
      }
    );
  };

  const handler = (toastId) => {
    const bodyRequest = {
      userId: userData?._id,
      displayName: form.gameName,
      tagline: form.tagline,
    };
    if (Object.keys(error).length) {
      toast.dismiss(toastId);
      return;
    }
    toast.dismiss(toastId);
    updateProfile(bodyRequest)
      .unwrap()
      .then(() => {
        setDisplayName();
        fetchUserData();
        setPlayerIDProgress(true);
        toast.dismiss();
        showToast("Game name changed!", SuccessOption);
      })
      .catch((err) => {
        toast.dismiss();
        showToast(
          err?.data?.error || "Error registering game name and tagline!",
          ErrorOption
        );
      });
  };

  const handleConnectDisconnect = (walletAddress) => {
    updateProfile({
      userId: userData?._id,
      walletId: walletAddress,
    })
      .unwrap()
      .then(() => {
        // showToast(
        //   `Wallet ${walletAddress ? "connected" : "disconnected"}!`,
        //   SuccessOption
        // );
      })
      .catch((err) => {
        // showToast(
        //   err?.data?.error || "Error registering game name and tagline!",
        //   ErrorOption
        // );
      });
  };

  const randomHashGenerator = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let hash = "#";

    const hashLength = 4;

    for (let i = 0; i < hashLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      hash += chars.charAt(randomIndex);
    }

    return `${hash}`;
  };

  const fieldValidations = () => {
    if (!form.gameName || form.gameName === "") {
      setError({ gameName: "Game name is required" });
      return { gameName: "Game name is required" };
    } else {
      setError({});
    }
  };

  return (
    <div className="tbbox playerid" id="playerid">
      <h5>Player ID</h5>
      <p>
        Your unique Player ID connects you with others through the game's social
        features.
      </p>
      <form className="input-container" autoComplete="off">
        <div className="two-inputs">
          <label
            className={
              form.gameName
                ? `has-value ${isDisabled && "disabled"}`
                : `${isDisabled && "disabled"}`
            }
          >
            <span>Game Name</span>
            <input
              type="text"
              value={form.gameName}
              onChange={(e) =>
                setForm({
                  ...form,
                  gameName: e.target.value,
                })
              }
              disabled={isDisabled ? true : false}
              autoComplete="off"
              className={error.gameName ? "input-error" : ""}
            />
          </label>
          <label
            className={
              form.gameName || tagline
                ? `has-value ${isDisabled && "disabled"}`
                : `${isDisabled && "disabled"}`
            }
          >
            <span>tagline</span>
            <input
              type="text"
              value={
                userData.gameName
                  ? form.gameName + userData.tagline.Value
                  : form.gameName + tagline
              }
              disabled
              autoComplete="off"
            />
          </label>
        </div>
        <div>
          {error?.gameName && (
            <div className="error-message">{error.gameName}</div>
          )}
        </div>
      </form>
      <button
        style={{ marginBottom: "20px" }}
        className={`btn update-btn ${isDisabled && "btn-disabled"}`}
        onClick={handleUsernameChange}
        ref={btnRef}
        title={
          isDisabled
            ? "You can change your username once per year."
            : "Change username."
        }
      >
        Update
      </button>
      <h5>Linked Wallet</h5>
      <p>Link your crypto wallet.</p>
      <div className="multi-input-cont">
        {address ? (
          <label
            className={
              address
                ? "has-value wallet-label disabled"
                : "wallet-label disabled"
            }
          >
            <span>Wallet id</span>
            <input
              disabled
              type="text"
              value={address && address}
              ref={textRef}
              autoComplete="off"
              className={
                error.isError ? "input-error wallet-input" : "wallet-input"
              }
            />
            <img className="wallet-icon" src={walletIcon} alt="icon" />
            <div
              className="copy-btn"
              style={{ cursor: "pointer" }}
              onClick={copyToClipboard}
            >
              <img src={copyIcon} alt="link-icon" />
            </div>
          </label>
        ) : (
          <button
            style={{ marginBottom: "20px" }}
            className="btn update-btn"
            onClick={() => open()}
          >
            {address
              ? address?.slice(0, 5) +
                "..." +
                address?.slice(address.length - 3)
              : "CONNECT WALLET"}
          </button>
        )}
      </div>
      {address && (
        <button
          style={{ marginBottom: "20px" }}
          className="btn update-btn"
          onClick={() => {
            disconnect();
            handleConnectDisconnect("");
          }}
        >
          disconnect
        </button>
      )}
    </div>
  );
};

export default PlayerID;
