import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "../../Components/DmenuItem";
import { DashboardMenu } from "../../Constants/list";
import Button from "../../Components/Dbutton";
import DcardBox from "../../Components/DcardBox";
import Dprofile from "../../Components/DprofileCard";
import ReferralLinkModal from "./referralLinkModal";
import DloginModal from "../../Components/DloginModal";
import Dtoast from "../../Components/Dtoast";
import { useSelector } from "react-redux";
import NavLink from "../../Components/Dlinks";
import { activeTaskCount } from "../../store/slices/socialTaskSlice";
import { playSound } from "../../utils/helper";
import { useGetUserRankMutation } from "../../services/highscroes";
import { useGetActiveSocialTaskMutation } from "../../services/socialTask";
const logo = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Dark.svg`;
const LeftArrow = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/left-arrow.svg`;
const CloseIcon = `${process.env.REACT_APP_S3_BUCKET}/dashboard-images/close-icon.svg`;
const Logo = `${process.env.REACT_APP_S3_BUCKET}/Images/iconlogo-Dark.svg`;
const PlusIcon = `${process.env.REACT_APP_S3_BUCKET}/Images/plus-icon.svg`;
const MenuChangeSound = `${process.env.REACT_APP_S3_BUCKET}/sounds/button_UI_click.wav`;

const Dsidebar = ({ onOpen, isClose, onCloseSiderbar }) => {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);
  const activeTask = useSelector(activeTaskCount);
  const firstTimeLoad = useRef(false);

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLinkGenerate, setIsLinkGenerate] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [getCurrentUserRank] = useGetUserRankMutation();
  const [getAllActiveTasks] = useGetActiveSocialTaskMutation();

  const [data, setData] = useState({
    id: user?.playFabId,
    image: user?.avatarUrl || Logo,
    title: user?.displayName || user?.userName,
    status: "Online",
    count: user?.rank,
    subItem: true,
  });

  useEffect(() => {
    if (user && !firstTimeLoad.current) {
      setData({
        id: user?.playFabId,
        image: user?.avatarUrl || Logo,
        title: user?.displayName || user?.userName,
        status: "Online",
        subItem: true,
      });

      const requestParams = {
        search: user.playFabId,
      };
      getCurrentUserRank(requestParams)
        .unwrap()
        .then((res) => {
          setData((prev) => ({
            ...prev,
            count: res?.data?.leaderboard?.[0]?.rank || 0,
          }));
          firstTimeLoad.current = true;
        })
        .catch((err) => {
          console.log("failed to fetch current user rank", err);
        });

      getAllActiveTasks(user?._id);
    }
  }, [getCurrentUserRank, getAllActiveTasks, user]);

  return (
    <>
      <div>
        <div className="d-logos">
          <button
            className="d-bsr-close"
            onClick={() => {
              onOpen();
              playSound(MenuChangeSound);
            }}
          >
            <img src={LeftArrow} alt="icon" />
          </button>
          <button
            className="d-bsr-back"
            onClick={() => {
              playSound(MenuChangeSound);
              onCloseSiderbar();
            }}
          >
            <img src={CloseIcon} alt="icon" />
          </button>
          <NavLink text="Back to site" link="/" extraClasses="mobile-back" />

          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        {user ? (
          <Dprofile data={data} isdropdown isClose={isClose} />
        ) : (
          <div className="flex sign-in-flex">
            <a
              href={process.env.REACT_APP_SIGNUP_URL}
              className="sign-btn"
              onClick={() => playSound(MenuChangeSound)}
            >
              Sign up
            </a>
            <a
              href={process.env.REACT_APP_LOGIN_URL}
              className="sign-btn d-gradient-text"
              onClick={() => playSound(MenuChangeSound)}
            >
              Sign in
            </a>
          </div>
        )}

        <div className="menu-list">
          {useMemo(
            () => (
              <ul>
                {DashboardMenu.map((item, index) => {
                  const count =
                    item.link === "/active-task" ? activeTask : item.count;
                  return (
                    <li key={index} onClick={onCloseSiderbar}>
                      <MenuItem
                        icon={
                          pathname === item.link ? item.activeImage : item.icon
                        }
                        text={item.text}
                        link={item.link}
                        taskCount={count}
                        LeftIcon={true}
                        isActive={pathname === item.link}
                        onClick={() => playSound(MenuChangeSound)}
                      />
                    </li>
                  );
                })}
              </ul>
            ),
            [activeTask, onCloseSiderbar, pathname]
          )}
        </div>
      </div>
      <div className={`d-refer isOpen`}>
        {!isClose ? (
          <DcardBox variant="primary-contained" extraClasses="refer-card">
            <h2>referral link</h2>
            {!isLinkGenerate ? (
              <>
                <p>You don't have a referral link yet</p>
                <Button
                  variant="primary-contained"
                  text="create"
                  onClick={() => {
                    setIsModelOpen(!isModelOpen);
                    playSound(MenuChangeSound);
                  }}
                />
              </>
            ) : (
              <>
                <p>Here's your referral link</p>
                <Button
                  variant="primary-contained"
                  text="Show link"
                  onClick={() => {
                    setIsModelOpen(!isModelOpen);
                  }}
                />
              </>
            )}
          </DcardBox>
        ) : (
          <DcardBox
            variant="primary-contained"
            extraClasses="refer-card cursor-pointer"
            onClick={() => {
              setIsModelOpen(!isModelOpen);
              playSound(MenuChangeSound);
            }}
            style={{ borderRadius: "16px" }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={PlusIcon}
                alt="icon"
                width={20}
                height={20}
                style={{ opacity: 0.6 }}
              />
            </div>
            <div
              style={{
                fontSize: "11px",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              {isLinkGenerate ? "Show Link" : "Referrel Link"}
            </div>
          </DcardBox>
        )}
      </div>
      {user ? (
        <ReferralLinkModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
          isLinkGenerated={(value) => {
            setIsLinkGenerate(value ? true : false);
            setIsSuccess(value ? true : false);
          }}
        />
      ) : (
        <DloginModal
          onClose={() => {
            setIsModelOpen(false);
          }}
          isActive={isModelOpen}
        />
      )}

      {isSuccess && (
        <Dtoast
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
          message="Your referral link has been copied successfully. Share it now to start earning!"
        />
      )}
    </>
  );
};

export default Dsidebar;
