import { createSlice } from "@reduxjs/toolkit";
import { combatEarningApi } from "../../services/combatEarning";
import { mergeData } from "../../utils/helper";
import { eraningDataList } from "../../Constants";

const initialState = {
  data: [],
};

const combatEarningSlice = createSlice({
  name: "combatEarning",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      combatEarningApi.endpoints.getCombatCards.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        console.log(data);
        state.data = mergeData(data, eraningDataList(data));
      }
    );
    builder.addMatcher(
      combatEarningApi.endpoints.getCombatEarning.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        const updatedArray = state.data?.map((item) => {
          const apiEntry = data.find((data) => data._id === item.type);
          return {
            ...item,
            totalRewardEarned: apiEntry ? `${apiEntry.totalRewardEarned.toLocaleString()}` : "0",
          };
        });
        
        state.data = updatedArray;
      }
    );
  },
});

export default combatEarningSlice;
