import { createSlice } from "@reduxjs/toolkit";
import { friendsApi } from "../../services/friends";

const initialState = {
  friends: [],
  friendsCount: 0,
  totalReferUser: 0,
  friendsTask: [],
};

const friendsSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      friendsApi.endpoints.getFriends.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.friends = data?.data;
        state.friendsCount = data?.pagination?.totalItems;
        state.totalReferUser = data?.pagination?.totalItems;
      }
    );
    // builder.addMatcher(
    //   friendsApi.endpoints.getFriendTask.matchFulfilled,
    //   (state, { payload }) => {
    //     const { data } = payload;
    //     state.friendsTask = data;
    //   }
    // );
    // builder.addMatcher(
    //   friendsApi.endpoints.getLoginUserFriendTask.matchFulfilled,
    //   (state, { payload }) => {
    //     const { data } = payload;
    //     state.friendsTask = data;
    //   }
    // );
  },
});

export default friendsSlice;
