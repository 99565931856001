import { baseUrl, prepareHeaders } from "./_utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUserById: builder.mutation({
      query: (id) => ({
        url: `/users/get-user-by-playFabId/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),

    loginWithWallet: builder.mutation({
      query: (body) => ({
        url: `/auth/login-with-wallet`,
        method: "POST",
        body: body,
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (body) => ({
        url: `/users/userSetting`,
        method: "PATCH",
        body,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useGetUserByIdMutation, useLoginWithWalletMutation ,useUpdateUserProfileMutation } = userApi;
